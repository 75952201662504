import { createRouter, createWebHistory } from "@ionic/vue-router";
import Category from "../views/Category.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Play from "../views/Play.vue";
import Root from "../views/Root.vue";

const routes = [
    {
        path: "/",
        name: "Root",
        component: Root,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
    },
    {
        path: "/play/:id",
        name: "Play",
        component: Play,
    },
    {
        path: "/category/:id",
        name: "Category",
        component: Category,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
