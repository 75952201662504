import tables from "@/store/thecore/tables";
import { createStore } from "vuex";

const store = createStore({
    modules: {
        tables,
    },
    state: {
        languages: {},
        browserLang: null
    },
    getters: {
        getAllLanguages: (state) => {
            return state.languages;
        },
        getLanguagesForId: (state) => (id) => {
            console.log(id);
            return state.languages;
        },
        getBrowserLang: (state) => {
            return state.browserLang;
        }
    },
    mutations: {
        addLanguageToId (state, payload) {
            state.languages[payload.id].push(payload.language);
        },
        initLanguageArrayForId (state, payload) {
            state.languages[payload.id] = [payload.language];
        },
        setBrowserLang(state) {
            const currLang = (window.navigator.language || window.navigator.userLanguage);
            state.browserLang = (currLang ? currLang : "").split("-")[0];
        }
    },
    actions: {},
});

export default store;
