// URL settings
export const backendUrl="https://admin.deventa.me";
export const backendRelativePath="";
export let backendURLWithRelativePath=`${backendUrl}${backendRelativePath}`;
export let apiURL=`${backendURLWithRelativePath}/api/v2`;
export const chatURL="https://chat.deventa.me";

// These consts, if available, do manage public sites
// They will access with a token
// and have all the same user (the one, on the backend, which is associated to this token)
// If publicAccess is true, then the other constants become relevant, otherwise, not
export const publicAccess = false;
export const accessToken = "";
export const disableChat = false;
export const feName = "Deventa.IO";
