<template>
	<ion-page>
		<!-- :style="{ backgroundImage: `url( ${require(`@/assets/login_background.png`)} )`}" -->
		<div class="graphical-container center-login">
			<div class="left-login">
				<div class="graphical-info">
					<div class="logo text-center">
						<img :src="require(`@/assets/customer_logo.png`)" />
					</div>
					<div class="welcome text-nowrap h1">{{ welcomeBack }}</div>
					<div class="motto text-left">{{ welcomeSubtitle }}</div>
				</div>
			</div>
			<div id="login-form" class="right-login">
				<h2 class="title">{{ signIn }}</h2>
				<!-- <div class="login-form"> -->
				<ion-item class="input-form">
					<ion-icon :icon="mailOutline"></ion-icon>
					<ion-input type="email" placeholder="Email" v-model="credentials.auth.email" required></ion-input>
				</ion-item>
				<ion-item class="input-form">
					<ion-icon :icon="lockClosedOutline"></ion-icon>
					<ion-input type="password" placeholder="Password" v-model="credentials.auth.password" required>
					</ion-input>
				</ion-item>
				<div class="checkbox-remember">
					<ion-checkbox class="checkbox-square"></ion-checkbox>
					<ion-label class="checkbox-label">Ricordami</ion-label>
				</div>
				<ion-button color="primary" size="large" expand="block" fill="solid" :disabled="!tmpSubmit"
					@click="executeLogin()" slot:end shape="round">
					<ion-spinner v-if="loading"></ion-spinner>
					<span v-else>{{ signIn }}</span>
				</ion-button>
				<!-- <span>Hai dimenticato la password? <a>Richiedi</a></span> -->
				<!-- </div> -->
			</div>
		</div>
		<div class="login-page-footer footer">
			<img class="main-menu-app-logo" :src="require(`@/assets/logo_application.png`)" />
		</div>
	</ion-page>
</template>

<style scoped>
	* {
		box-sizing: border-box;
	}

	a {
		padding-left: 10px;
		color: var(--ion-color-primary);
	}

	.checkbox-remember {
		font-size: 1vw;
		text-align: left;
		color: var(--ion-color-medium);
		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;
		width: 100%;
		margin-top: 0.5em;
		margin-left: 40px;
	}

	.checkbox-remember ion-checkbox {
		margin-right: .4em;
	}

	.checkbox-label {
		margin-left: .5em;
	}

	.ion-page,
	html {
		font-size: 2vw;
		background-image: linear-gradient(to right,
				var(--ion-color-primary),
				var(--ion-color-secondary),
				var(--ion-color-primary));
		background-color: var(--ion-color-primary);
	}

	div.ion-page {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0;
	}

	.graphical-container {
		width: 80vw;
		height: 55vh;
		border-radius: 5em !important;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.vertical-center {
		justify-content: center;
	}

	.left-login {
		width: 55%;
		height: 100%;
		background: radial-gradient(circle at -50% 50%,
				#e2e2e2 35%,
				#d9d9d9 35%,
				#d9d9d9 39%,
				#e2e2e2 39%,
				#e2e2e2 45%,
				#d9d9d9 45%,
				#d9d9d9 52%,
				#e2e2e2 52%,
				#e2e2e2 60%,
				#d9d9d9 60%,
				#d9d9d9 70%,
				#e2e2e2 70%);
		border-top-left-radius: 2.5em;
		border-bottom-left-radius: 2.5em;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#login-form {
		width: 45%;
		height: 100%;
		background-color: white;
		border-top-right-radius: 2.5em;
		border-bottom-right-radius: 2.5em;
		padding-left: 5vw;
		padding-right: 5vw;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	#login-form>* {
		margin-bottom: 1em;
	}

	#login-form .title {
		font-weight: bold;
		color: var(--ion-color-primary);
		font-size: 2.5vw;
		text-align: left;
		width: 100%;
	}

	.graphical-info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.graphical-info .logo {
		width: min(30vw, 360px);
	}

	.graphical-info .welcome {
		margin-top: 0.5em;
		color: var(--ion-color-primary);
		font-size: 4vw;
		font-weight: bold;
	}

	.graphical-info .motto {
		font-size: 0.8vw;
		width: 80%;
	}

	.login-page-footer {
		position: fixed;
		left: 0;
		bottom: 2em;
		width: 100%;
		color: white;
		text-align: center;
	}

	.login-page-footer img {
		width: 13vw;
	}

	.input-form ion-icon:first-of-type {
		margin-right: 1em;
	}

	ion-button {
		color: white;
		--background: var(--ion-color-primary);
		width: 100%;
	}

	/* Tablets */
	@media screen and (max-width: 1080px) {
		.checkbox-remember {
			font-size: 2vw;
		}

		.graphical-container {
			flex-direction: column;
			border-radius: unset !important;
		}

		.left-login {
			width: 100%;
			border-top-left-radius: 2.5em;
			border-bottom-left-radius: unset;
			border-top-right-radius: 2.5em;
		}

		#login-form {
			width: 100%;
			border-bottom-right-radius: 2.5em;
			border-bottom-left-radius: 2.5em;
			border-top-right-radius: unset;
		}
	}
</style>

<script>
	import {
		defineComponent
	} from "vue";

	import {
		IonInput,
		IonButton,
		IonIcon,
		IonItem,
		IonCheckbox,
		IonLabel,
		IonPage,
		IonSpinner
	} from "@ionic/vue";
	import {
		personOutline,
		mailOutline,
		lockClosedOutline
	} from "ionicons/icons";
	import {
		openToast
	} from "../plugins/common.js";

	export default defineComponent({
		name: "Login",
		props: {
			signIn: {
				type: String,
				default: "Sign In", //"ACCEDI",
			},
			welcomeBack: {
				type: String,
				default: "Welcome back!", //"Benvenuto",
			},
			welcomeSubtitle: {
				type: String,
				default: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid ut labore et dolore magna aliqua.", //"Benvenuto",
			},
		},
		components: {
			IonPage,
			IonInput,
			IonButton,
			IonIcon,
			IonItem,
			IonCheckbox,
			IonLabel,
			IonSpinner
		},
		data: () => ({
			loading: false,
			credentials: {
				auth: {
					email: null, //"admin@example.com",
					password: null, //"403906464d",
				},
			},
			currentData: {
				user: null,
			},
			tmpSubmit: true,
			personOutline,
			mailOutline,
			lockClosedOutline,
			openToast,
		}),
		watch: {
			loading() {
				if (this.loading === false) {
					this.clearAuthFields();
				} else {
					localStorage.removeItem("user");
				}
			}
		},
		ionViewDidEnter() {
			if (this.currentData.user && this.currentData.user.id) {
				this.insertVisitedPages();
			}
		},
		methods: {
			clearAuthFields() {
				this.credentials.auth.email = null;
				this.credentials.auth.password = null;
			},
			executeLogin() {
				this.loading = true;
				this.$store.dispatch("tables/login", {
					model: "authenticate",
					data: this.credentials,
				}).then((data) => {
					// console.log("Utente Autenticato");
					if (data) {
						this.currentData.user = data;
						localStorage.setItem(
							"user",
							JSON.stringify(this.currentData.user)
						);
						// If there's actually a user with data attached, then go to home
						this.$router.push("/home");
					}
					this.loading = false;
				}).catch((error) => {
					this.openToast(error);
					this.loading = false;
				});
			},
			insertVisitedPages() {
				let activity = {
					visited_page: {
						page: this.$route.name,
						user_id: this.currentData.user.id,
					},
				};
				this.$store.dispatch("tables/create", {
					model: "visited_pages",
					data: activity
				}).catch((error) => {
					this.openToast(error);
				});

				// To Google analytics
				this.$gtag.pageview({
					page_path: this.$route.path,
				})
			},
		},
	});
</script>
